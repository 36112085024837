<template>
  <div :class="`border-box gridbox mx-auto max-width-3 mx-auto ${bgColor}`">
    <!-- Form -->
    <div
      :class="`mx-auto max-width-3 md-p4 p3 border-box gridbox-element email-msg-animation col-12 ${
        isLoading || emailSent ? 'email-hide' : ''
      }`"
    >
      <h2 :class="`open-sans-bold center h1 ${headingColor}`">
        {{ $t("prize_draw_title") }}
      </h2>
      <p :class="`h5 ${sublineColor} open-sans-regular center mb3`">
        {{ $t("prize_draw_subline_p1")
        }}<a
          v-if="tNCLink != ''"
          :href="tNCLink"
          target="_blank"
          rel="noopener"
          class="color-1"
          >{{ $t("prize_draw_subline_p2") }}</a
        >{{ $t("prize_draw_subline_p3") }}
      </p>
      <p
        :class="`h5 ${bulletPointColor} open-sans-regular mb2 rsf-prize-form-bullet-point flex gap1`"
        v-html="line1"
      ></p>
      <p
        :class="`h5 ${bulletPointColor} open-sans-regular mb2 rsf-prize-form-bullet-point flex gap1`"
        v-html="line2"
      ></p>
      <p
        :class="`h5 ${bulletPointColor} open-sans-regular mb2 rsf-prize-form-bullet-point flex gap1`"
        v-html="line3"
      ></p>
      <p
        :class="`h5 ${bulletPointColor} open-sans-regular mb2 rsf-prize-form-bullet-point flex gap1`"
        v-html="line4"
      ></p>

      <div class="flex flex-column gap2 mt3">
        <div class="md-flex flex flex-column gap2">
          <input
            type="text"
            v-model="name"
            :placeholder="$t('prize_draw_placeholder_name')"
            class="rsf-input h5 color-black bg-color-white open-sans-regular border-box col-12"
          />
          <input
            type="text"
            v-model="lastName"
            :placeholder="$t('prize_draw_placeholder_lastname')"
            class="rsf-input h5 color-black bg-color-white open-sans-regular border-box col-12"
          />
        </div>
        <div class="md-flex flex flex-column gap2">
          <div class="col-12">
            <input
              type="email"
              v-model="email"
              :placeholder="$t('prize_draw_placeholder_email')"
              class="rsf-input h5 color-black bg-color-white open-sans-regular border-box col-12"
            />
          </div>

          <div
            class="rsf-select-wrapper mx-auto mb3 relative col-12"
            style="max-width: 550px"
          >
            <svg
              class="pointer-events-none svg-color-black"
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.7695 1.5L5.88481 6.5L1.00008 1.5"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <select
              class="rsf-select h5 color-black open-sans-regular pointer"
              v-model="country"
              :style="`${
                Object.keys(country).length == 0
                  ? 'color: var(--rsf-2022-color-13)'
                  : ' color: var(--rsf-2022-color-black)'
              }`"
            >
              <option :value="{}" disabled isSelected>
                {{ $t("prize_draw_placeholder_country") }}
              </option>
              <option
                v-for="country in countries"
                :key="country.id"
                :value="country"
              >
                {{ country.countryname }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <label
        :class="`checkbox-input-wrapper flex items-center pointer gap1 h5 ${checkBoxTextColor}`"
        for="user_signup"
      >
        <input
          class="w-fit-content"
          type="checkbox"
          id="user_signup"
          name="user_signup"
          v-model="userSignup"
        />
        <span class="checkbox-checkmark"></span>
        <span class="inline-block"
          >{{ $t("prize_draw_signup_checkbox_label_p1")
          }}<a
            v-if="tNCLink != ''"
            :href="tNCLink"
            target="_blank"
            rel="noopener"
            class="color-1 underline"
            >{{ $t("prize_draw_signup_checkbox_label_p2") }}</a
          >{{ $t("prize_draw_signup_checkbox_label_p3") }}</span
        >
      </label>

      <div
        @click="sendEmail"
        :class="`rsf-btn ${btnVariant} ${btnTextColor} ${btnBgColor} mx-auto mt3 ${
          formValid ? '' : 'op50 pointer-events-none'
        }`"
      >
        {{ $t("prize_draw_submit_btn_label") }}
      </div>
    </div>

    <!-- Email success -->
    <div
      :class="`gridbox-element email-msg-animation flex flex-column justify-center mx-auto max-width-3 md-p4 p3 border-box col-12 ${
        !emailSent ? 'email-hide' : ''
      }`"
    >
      <p class="h4 center color-black open-sans-regular mb1">
        {{ $t("prize_draw_submit_api_success_label") }}
      </p>
      <h3 :class="`h1 center color-black open-sans-bold ${headingColor} mb4`">
        {{ $t("prize_draw_join_facebook_group_notice") }}
      </h3>
      <div
        class="flex flex-wrap flex-column gap2 items-center mx-auto justify-center"
      >
        <a
          v-if="siteLanguage != 'cn'"
          target="_blank"
          rel="noopener norefferer"
          href="https://www.facebook.com/groups/817257271620591"
          :class="`rsf-btn ${btnVariant} ${btnTextColor} ${btnBgColor}`"
          >{{ $t("prize_draw_join_facebook_group") }}</a
        >
      </div>
    </div>

    <!-- Loader -->
    <div
      :class="`gridbox-element email-msg-animation flex flex-column justify-center mx-auto max-width-3 md-p4 p3 border-box col-12 ${
        !isLoading ? 'email-hide' : ''
      }`"
    >
      <img
        class="block center mx-auto pb3 loader-spinner"
        src="https://static.redseafish.com/wp-content/uploads/2023/09/07130304/spinner2.gif"
      />
      <p :class="`h1 center color-black open-sans-bold mb4 ${headingColor}`">
        {{ $t("prize_draw_submit_api_loading_label") }}
      </p>
    </div>

    <!-- E-Mail send failed -->
    <div
      class="gridbox-element h100 flex flex-column justify-center mx-auto max-width-3 md-p4 p3 border-box bg-color-11 relative z3 col-12"
      v-if="emailFailed"
    >
      <h3 :class="`h1 center color-black open-sans-bold mb4 ${headingColor}`">
        {{ $t("prize_draw_submit_api_failure_label") }}
      </h3>
      <div
        :class="`rsf-btn ${btnVariant} ${btnTextColor} ${btnBgColor} mx-auto mt3`"
        @click="resetForm"
      >
        {{ $t("prize_draw_submit_api_failure_back") }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "App",
  props: [
    "siteLanguage",
    "line1",
    "line2",
    "line3",
    "line4",
    "newsletterTag",
    "bgColor",
    "headingColor",
    "sublineColor",
    "bulletPointColor",
    "checkBoxTextColor",
    "tNCLink",
    "btnVariant",
    "btnBgColor",
    "btnTextColor",
  ],
  data() {
    return {
      name: "",
      lastName: "",
      email: "",
      country: {},
      userSignup: true,
      countries: [],
      isLoading: false,
      emailSent: false,
      emailFailed: false,
      emailIsValid: false,
    };
  },
  created() {
    if (this.siteLanguage == "") {
      this.$i18n.locale = "en";
      this.switchLocale();
    } else {
      this.$i18n.locale = this.siteLanguage;
      this.switchLocale();
    }
  },
  mounted() {
    this.fetchCountries();
  },
  methods: {
    validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    resetForm() {
      this.name = "";
      this.lastName = "";
      this.email = "";
      this.country = {};
      this.userSignup = false;
      this.emailSent = false;
      this.emailFailed = false;
    },
    sendEmail() {
      this.isLoading = true;
      let browserLang = navigator.language || navigator.userLanguage;
      let url =
        "https://data.redseafish.com/public-api/newsletter/priceform/add/json";
      axios
        .post(url, {
          email: this.email,
          name: this.lastName,
          prename: this.name,
          country_isocode: this.country.isocode2,
          lang: this.siteLanguage,
          browserLanguage: browserLang,
          tag: this.newsletterTag,
        })
        .then((res) => {
          this.isLoading = false;
          this.emailSent = true;
        })
        .catch((e) => {
          this.isLoading = false;
          this.emailFailed = true;
        });
    },
    fetchCountries() {
      let that = this;
      let url =
        "https://data.redseafish.com/public-api/list-countries/prizeform/json";
      axios
        .post(url, { lang: this.siteLanguage })
        .then(function (response) {
          that.countries = response.data.data;
          for (let i = 0; i < that.countries.length; i++) {
            if (that.countries[i].isocode2 == "XX") {
              let otherCountries = that.countries[i];
              that.countries.splice(i, 1);
              that.countries.push(otherCountries);
            }
          }
        })
        .catch(function (error) {
          that.emailFailed = true;
        });
    },
    switchLocale() {
      var locale = this.$i18n.locale;
      const to = this.$router.resolve({ params: { lang: locale } });
      this.$router.push(to.location).catch((err) => {
        if (
          err.name !== "NavigationDuplicated" &&
          !err.message.includes(
            "Avoided redundant navigation to current location"
          )
        ) {
          // But print any other errors to the console
          console.log(err);
        }
      });
    },
  },
  watch: {
    email() {
      this.emailIsValid = this.validateEmail(this.email);
    },
  },
  computed: {
    formValid() {
      if (
        this.emailIsValid &&
        this.name != "" &&
        this.lastName != "" &&
        this.userSignup &&
        Object.keys(this.country).length > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
.email-msg-animation {
  transition: 0.5s ease;
  opacity: 1;
  transition-delay: 0.5s;
}

.email-hide {
  opacity: 0;
  pointer-events: none;
  transform: translateY(50px);
  transition-delay: 0s;
}
</style>
